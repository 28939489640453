import { t as getLang } from 'react-switch-lang';
import { getIntegrationPlatform } from './IntegrationPlatform';
import { isLoadhubUserGroup, isShoppersUserGroup } from './UserGroup';

export * from 'react-switch-lang';

export const t = (key, params, lang) => {
  if (isLoadhubUserGroup()) {
    const loadhubKey = `${key}_Loadhub`;
    const loadhubText = getLang(loadhubKey, params, lang);
    if (loadhubKey !== loadhubText) return loadhubText;
  }

  if (isShoppersUserGroup()) {
    const shoppersKey = `${key}_Shoppers`;
    const shoppersText = getLang(shoppersKey, params, lang);
    if (shoppersKey !== shoppersText) return shoppersText;
  }

  const integrationPlatform = getIntegrationPlatform();
  if (!integrationPlatform) return getLang(key, params, lang);
  const platformKey = `${key}_${integrationPlatform}`;
  const platformText = getLang(platformKey, params, lang); // returns the key if key is not found
  return platformKey === platformText ? getLang(key, params, lang) : platformText;
};
